import '../scss/bottom-action-bar.scss';

document.addEventListener('DOMContentLoaded', () => {
    const primaryActionButton = document.getElementById('action-primary');
    const primaryItemsList = document.querySelector('.list-items__primary');

    const secondaryActionButton = document.getElementById('action-secondary');
    const secondaryItemsList = document.querySelector('.list-items__secondary');

    if (primaryActionButton) {
        primaryActionButton.addEventListener('click', () => {
            primaryActionButton.classList.add('d-none');
            primaryItemsList.classList.add('d-none');
            secondaryActionButton.classList.remove('d-none');
            secondaryItemsList.classList.remove('d-none');
        });
    }

    if (secondaryActionButton) {
        secondaryActionButton.addEventListener('click', () => {
            secondaryActionButton.classList.add('d-none');
            secondaryItemsList.classList.add('d-none');
            primaryActionButton.classList.remove('d-none');
            primaryItemsList.classList.remove('d-none');
        });
    }
});